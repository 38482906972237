import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'en'}>
      <section id="one">
        <header className="major">
          <h2>Preventive music therapy during pregnancy</h2>
        </header>
        <p>
          <strong>Preventive music therapy during pregnancy</strong> is a powerful tool for expectant mothers who want to promote their emotional and physical well-being during this special stage. Through music, relaxation, emotional balance, and the prenatal bond between mother and baby are encouraged.
        </p>
        <h2>Benefits of music therapy during pregnancy</h2>
        <p>
          <ul>
            <li><strong>Stress and anxiety reduction:</strong> Through relaxation techniques and active listening, music helps reduce emotional tension and improve overall well-being.</li>
            <li><strong>Prenatal bonding:</strong> Music facilitates emotional communication between mother and baby, promoting connection and calm.</li>
            <li><strong>Sensory stimulation for the baby:</strong> Babies, even in the womb, can perceive sounds and melodies, aiding in their cognitive and emotional development.</li>
            <li><strong>Support during labor:</strong> Music therapy prepares mothers to face the birthing process with more confidence, using music for relaxation and focus.</li>
            <li><strong>Promoting physical well-being for the mother:</strong> Through music, breathing and movement techniques can be used to alleviate common pregnancy discomforts, such as back pain or muscle tension.</li>
            <li><strong>Emotional support in groups:</strong> Group sessions allow mothers to share experiences, bond with other women going through a similar stage, and feel supported, which fosters a sense of community and mutual support.</li>
          </ul>
        </p>
        <p>
          Music therapy sessions or workshops during pregnancy are group-based, tailored to the needs of each woman, and offered in a safe and respectful space. If you are looking for a way to take care of yourself and your baby while preparing for motherhood, music therapy is an ideal option to experience pregnancy in a more conscious and balanced way.
        </p>
      </section>
      <section id="three">
        <h2>Upcoming Events</h2>
        <p>
          To check the upcoming music stimulation workshops,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            click here!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
